export default {
  regexPhone(value) {    
    const regexPhone = /^\d{11}$/;
    return regexPhone.test(value)
  },
  regexEmail(value) {    
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(value)
  }
};
